function handlerPositionCol (col) {
  let text = ''
  if (Array.isArray(col)) {
    col.forEach(item => {
      text += item.position_name + '<br>'
    })
  }

  return text
}

function handlerAmountCol (col) {
  let text = ''
  if (Array.isArray(col)) {
    col.forEach(item => {
      text += item.amount + '<br>'
    })
  }

  return text
}

function handlerTypeCol (col) {
  return col?.name ?? ''
}

export {
  handlerPositionCol,
  handlerAmountCol,
  handlerTypeCol
}
