<template>
  <ModalForm
    v-model="formData"
    class="modal--lg"
    :title="title"
    text-btn-confirm="Упаковать"
    @request="onRequest"
    @reject="onReject"
  >

    <div class="packing__positions">
      <div
        v-for="(row, i) in rows"
        :key="'position' + i"
        class="d-flex gap-xxs mb-20 ai-fe">
        <VSelect
          v-model="row.position"
          label="Позиция"
          style="width: 400px"
          required
          item-text="title"
          :options="packingPositions"
        >
          <Validation for="required"/>
        </VSelect>
        <VInput
          v-model="row.amount"
          label="Кол-во"
          :required="maxValue(row, i) > 0"
          style="width: auto;"
          :pattern="/^\d+$/"
          :max-value="maxValue(row, i)"
          :custom-validation="maxValue(row, i) < 1 ? () => false : null"
        >
          <Validation for="required"/>
          <Validation for="pattern" key-text="num"/>
          <Validation for="maxValue">Количество не должно превышать {{ maxValue(row, i) }}</Validation>
          <Validation for="customValidation">Для данной позиции нет доступного количества</Validation>
        </VInput>
        <VButton
          v-if="row.plus"
          icon="create"
          size="sm"
          color="primary-sm"
          class="packing__btn"
          :disabled="rows.length === packingPositions.length"
          @click.native="addRowPosition(null, null)"
        />
        <VButton
          v-else
          icon="remove"
          size="sm"
          class="packing__btn"
          @click.native="removeRowPosition(i)"
        />
      </div>
    </div>

    <VSelect
      v-if="packing"
      label="Статус"
      class="mb-30"
      name="status"
      item-text="name"
      required
      :options="statuses"
    >
      <Validation for="required"/>
    </VSelect>

    <VSelect
      label="Вид тары"
      class="mb-30"
      name="package_type"
      required
      :options="getPackageTypes"
    >
      <Validation for="required"/>
    </VSelect>

    <VInput
      label="Номер упаковщика"
      name="packer"
      class="mb-30"
      required
      :pattern="/^\d+$/"
    >
      <Validation for="required"/>
      <Validation for="pattern" key-text="num"/>
    </VInput>
  </ModalForm>

</template>
<script>
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import VSelect from '@/components/Form/VSelect.vue'
import Validation from '@/components/Form/Validation.vue'
import VInput from '@/components/Form/Vinput/VInput'
import { mapActions, mapGetters } from 'vuex'
import VButton from '@/components/simple/button/VButton.vue'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { orderModel } from '@/entities/order'

export default {
  name: 'ModalPackaging',
  components: {
    VButton,
    Validation,
    VSelect,
    ModalForm,
    VInput
  },
  props: {
    title: {
      type: String,
      default: 'Создание упаковки'
    },
    packing: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      formData: {
        package_type: this.packing?.package_type ?? null,
        packer: this.packing?.packer ?? null,
        status: this.packing?.status ? orderModel.PACKING_STATUSES.find(item => item.value === this.packing.status) : orderModel.PACKING_STATUSES[1]
      },
      rows: []
    }
  },

  mounted () {
    if (this.packing) {
      let key = 0

      this.packing.position_id_amount.forEach(item => {
        key++
        const position = this.packingPositions.find(position => position.id === item.position_id)
        this.addRowPosition(position, item.amount, key === 1)
      })
    } else {
      this.addRowPosition(null, null, true)
    }
  },

  computed: {
    ...mapGetters('orderDetail', {
      positions: 'positions',
      packingPositions: 'packingPositions',
      currentOrderId: 'currentOrderId'
    }),
    ...mapGetters('records', {
      getPackageTypes: 'getPackageTypes'
    }),

    statuses () {
      return orderModel.PACKING_STATUSES
    }
  },
  methods: {
    ...mapActions('orderDetail', {
      addPacking: 'addPacking',
      updatePacking: 'updatePacking'
    }),

    async onRequest (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const params = {
          ...prepareFormDataId(data, ['package_type']),
          position_id_amount: this.preparePositionInfo(),
          order_id: this.currentOrderId,
          status: data.status.value
        }

        if (this.packing) {
          params.id = this.packing.id
          await this.updatePacking(params)
        } else {
          await this.addPacking(params)
        }

        this.onReject()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    preparePositionInfo () {
      const data = []
      this.rows.forEach(item => {
        data.push({
          position_id: item.position.id,
          amount: item.amount
        })
      })

      return data
    },

    addRowPosition (position, amount, plus = false) {
      this.rows.push({
        position: position,
        amount: amount,
        plus: plus
      })
    },

    removeRowPosition (key) {
      if (this.rows?.length !== 1) {
        this.rows.splice(key, 1)
      }
    },

    maxValue (row, index) {
      const currentPackingAmount = this.packing?.position_id_amount[index]?.amount

      return row?.position
        ? +row?.position?.value - +row?.position?.packingValue + (currentPackingAmount ? +currentPackingAmount : 0)
        : 0
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>

<style lang="scss">
.packing {
  &__positions {

  }

  &__btn {
    align-self: flex-end;
    & .btn__icon-wrap {
      @include size(43px)
    }
  }
}
</style>
