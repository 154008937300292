<template>
  <li
    class="nav-item"
    :class="[
      isActive && 'router-link-active',
      isDisabled && 'disabled',
      block + '-item'
    ]"
  >
    <div
      class="nav-item__link"
      :class="block + '-item__link'"
    >
      <svgicon
        v-if="navItem.icon"
        class="nav-item__icon"
        :class="block + '-item__icon'"
        :icon="iconPrefix + navItem.icon"
        width="20"
        height="20"
      />

      <TextCount
        class="nav-item__text"
        :class="block + '-item__text'"
        :text="navItem.title"
        :count="navItem.count"
      />

      <span v-if="navItem.countPacking" :class="{ 'color--dark-orange': !navItem.bgCount, 'color--white nav-item__counter ': navItem.bgCount }">
        {{ navItem.countPacking }}
      </span>
    </div>
  </li>
</template>

<script>
import TextCount from '@/components/simple/text/TextCount'
import { mixinNavItem } from '@/mixins/mixinNavItem'
export default {
  name: 'TabItem',
  components: {
    TextCount
  },
  mixins: [mixinNavItem]
}
</script>
