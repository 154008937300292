<template>
  <div>
    <AppAccordion
      class="info-order box"
      :visible-btn="visibleOrderNote && isOrderTab"
    >
      <div slot="header">
        <div class="box__header">
          <div class="flex gap-m">
            <TabsSwiper
              class="order-info-tabs tabs-swiper--border"
              :space-between="0"
            >
              <TabItem
                class="swiper-slide"
                v-for="tab of tabs"
                :key="tab.id"
                :navItem="tab"
                :is-active="activeTabId === tab.id"
                @click.native="setActiveTab(tab.id)"
              />
            </TabsSwiper>

            <OrderReclamationExternal
              v-if="order.reclamations && order.reclamations.length"
              :reclamations="order.reclamations"
            />

            <OrderProbes
              v-if="order.probes && order.probes.length"
              :probes="order.probes"
            />
          </div>

          <div class="buttons">
            <PrintWaybill
              v-if="!isOrderTab && !isPackingTab && waybill && $store.getters['permission/permissionPrintWaybill']"
              :waybill="waybill"
            />

            <VButton
              v-if="isPackingTab && $store.getters['permission/permissionPrintPacking']"
              text="Печать"
              size="sm"
              icon="print"
              :disabled="!selectedRowsPacking.length"
              @click.native="openModal('print-packing')"
            />

            <VButton
              v-if="isPackingTab && $store.getters['permission/permissionDeletePacking']"
              text="Удалить"
              size="sm"
              icon="remove"
              :disabled="!selectedRowsPacking.length"
              @click.native="openModal('delete-packing')"
            />

            <VButton
              v-if="order.status_id > 3 && permissionOrderPickup && !isPackingTab"
              text="Готовность к отгрузке"
              size="sm"
              icon="pickup"
              @click.native="openModalReadyForShipment"
            />

            <VButton
              v-if="$store.getters['permission/permissionCreateWaybill'] && !isOrderTab && !isPackingTab"
              :class="!waybill && 'filter-gray'"
              text="Редактировать"
              size="sm"
              icon="edit"
              @click.native="toggleEdit"
            />

            <VButton
              v-if="order.deleted_at && $store.getters['permission/isAdmin']"
              text="Восстановить"
              size="sm"
              icon="restore"
              @click.native="openModal('restore')"
            />

            <VButton
              v-if="permissionOrderUpdate && isOrderTab"
              text="Редактировать"
              size="sm"
              icon="edit"
              @click.native="
                $router.push({
                  name: 'OrderUpdate',
                  params: { orderId: order.id }
                })
              "
            />
          </div>
        </div>

        <div v-if="isOrderTab" class="info-order__flex mb-20">
          <div class="info-order__flex-col">
            <InfoTextBlock label-text="№ счета" :text="order.account_number"/>
            <InfoTextBlock
              label-text="Компания / Контакт"
              :text="order.client_name"
            />
            <InfoTextBlock label-text="Город" :text="order.city"/>
            <InfoTextBlock
              label-text="Тип заказа"
              :text="order.type && order.type.name"
            />
            <InfoTextBlock
              label-text="Область применения"
              :text="order.application_area_d && order.application_area_d.name"
            />
            <InfoTextBlock
              label-text="Назначение пружины"
              :text="order.appointment"
            />

            <OrderRelationOrders :order="order"/>
          </div>

          <div class="info-order__flex-col">
            <InfoTextBlock
              label-text="Менеджер по продажам"
              :text="orderManager"
            />
            <InfoTextBlock
              label-text="Инженер технолог"
              :text="orderTechManager"
            />
            <InfoTextBlock
              label-text="Сроки изготовления"
              :text="order.timing ? order.timing.name : '-'"
            />
            <InfoTextBlock
              label-text="Дата отгрузки заказа"
              :text="order.date_finished | date('date')"
            />
            <InfoTextBlock
              :link="order.google_link"
              label-text="Диск"
              :text="order.google_link"
            />

            <InfoTextBlock
              v-if="order.amo_order_link"
              label-text="Заказ в CRM"
              :link="order.amo_order_link"
              :text="order.amo_order_link"
            />

            <InfoText
              v-if="order.from_outer_reclamation"
              label="Ссылка на рекламацию"
            >
              <router-link
                class="link-reset v-link hover--main"
                :to="{ name: $store.state.pages.reclamationPages.details, params: { id: order.from_outer_reclamation.id } }"
              >
                Рекламация
              </router-link>
            </InfoText>
          </div>

          <div class="info-order__flex-col">
            <InfoTextBlock
              label-text="Причина просрочки заказа"
              :text="order.delay_reason && order.delay_reason.name"
            />

            <InfoTextBlock
              label-text="Примечание к причине просрочки"
              :text="order.note_production"
            />
          </div>
        </div>

        <WaybillCardDetails
          v-if="!isOrderTab && !isPackingTab && !isEdit"
          :waybill="waybill ? waybill : ''"
        />

        <WaybillEditForm
          v-if="!isOrderTab && !isPackingTab && isEdit"
          :order-id="order.id"
          :waybill="waybill"
          @update-success="turnOffIsEdit"
          @cancel="turnOffIsEdit"
        />

        <OrderInfoPackaging
          v-if="isPackingTab"
          @selectRowsPacking="selectRowsPacking"
        />
      </div>

      <div v-if="isOrderTab" class="info-order__note">
        <InfoTextBlock
          v-if="order.note_to"
          label-text="Примечание для тех. отдела"
          class="info--column"
          :text="order.note_to"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_manufacture"
          label-text="Примечание для производства"
          class="info--column"
          :text="order.note_manufacture"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_otk"
          label-text="Примечание для ОТК"
          class="info--column"
          :text="order.note_otk"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_technologist"
          label-text="Примечание для склада"
          class="info--column"
          :text="order.note_technologist"
          :has-tooltip="false"
        />
      </div>
    </AppAccordion>

    <ModalForm
      v-if="isModal === 'modal-shipment'"
      title="Редактировать заказ"
      only-btn-submit
      @request="submitReadyForShipment"
      @reject="closeModal"
    >
      <div class="modal-form__checkboxes-wrap column gap-xs">
        <VCheckbox
          v-model="isDocumentsAreReady"
          label="Документы готовы"
          name="is_documents_are_ready"
        />

        <VCheckbox v-model="isPickup" label="Самовывоз" name="is_pickup"/>
      </div>
    </ModalForm>

    <ModalOrderRestore v-if="isModal === 'restore' && $store.getters['permission/isAdmin']" @close="closeModal" />

    <PrintPacking
      v-if="isModal === 'print-packing'"
      :items="[]"
      :order-id="order.id"
      :selected-rows="selectedRowsPacking"
      @close="closeModal"
    />

    <ModalConfirmation
      v-if="isModal === 'delete-packing'"
      title="Уверены что хотите удалить упаковку?"
      @confirm="packingDeleteHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import ModalForm from '@/components/ui/Modal/ModalForm'
import VButton from '@/components/simple/button/VButton'
import VCheckbox from '@/components/Form/VCheckbox'
import { OrderReclamationExternal, OrderProbes } from '@/entities/order'
import TabItem from '@/components/ui/TabsSwiper/TabItem.vue'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper.vue'
import { WaybillCardDetails } from '@/entities/waybill'
import { WaybillEditForm } from '@/features/waybill/edit'
import { PrintWaybill } from '@/features/waybill/print-waybill'
import InfoText from '@/components/simple/text/InfoText.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { getUserFullName } from '@/utils/model/User'
import OrderRelationOrders from '@/components/production/order/OrderRelationOrders.vue'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import ModalOrderRestore from '@/components/production/order/ModalOrderRestore.vue'
import OrderInfoPackaging from '@/components/production/order/OrderInfoPackaging.vue'
import PrintPacking from '@/features/packing/print-packing/ui/PrintPacking.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import getValuesArray from '@/utils/array/getValuesArray'

// const TABS = [
//   {
//     id: 1,
//     title: 'Информация по заказу'
//   },
//   {
//     id: 3,
//     title: 'Упаковка'
//   },
//   {
//     id: 2,
//     title: 'Информация по отгрузке'
//   }
// ]

export default {
  name: 'OrderInfoBlock',
  components: {
    ModalConfirmation,
    PrintPacking,
    OrderInfoPackaging,
    ModalOrderRestore,
    InfoText,
    TabsSwiper,
    TabItem,
    AppAccordion,
    OrderRelationOrders,
    InfoTextBlock,
    ModalForm,
    VButton,
    VCheckbox,
    WaybillCardDetails,
    WaybillEditForm,
    PrintWaybill,
    OrderReclamationExternal,
    OrderProbes
  },
  mixins: [mixinModal, mixinIsEdit],

  data () {
    return {
      isUpdate: false,
      isPickup: false,
      isDocumentsAreReady: false,
      activeTabId: 1,
      selectedRowsPacking: {
        type: Array,
        default: () => []
      },
      tabsDefault: [
        {
          id: 1,
          title: 'Информация по заказу'
        },
        {
          id: 3,
          title: 'Упаковка',
          countPacking: null,
          bgCount: true
        },
        {
          id: 2,
          title: 'Информация по отгрузке'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      order: 'orderDetail/order',
      currentUserRole: 'currentUserRole',
      currentUserId: 'currentUserId'
    }),
    ...mapGetters('permission', [
      'permissionUpdateOrder',
      'permissionUpdateOrderByRole',
      'permissionUpdateOrderMarkersByRole'
    ]),
    ...mapState('waybill', {
      waybill: 'waybill'
    }),
    ...mapState('orderDetail', {
      packingCount: 'packingCount'
    }),

    permissionOrderUpdate () {
      if (this.permissionUpdateOrderByRole) return true

      if (this.permissionUpdateOrder) {
        return (
          this.userIsOrderCreatorOrManager ||
          this.userIsOrderPositionResponsible
        )
      }

      return false
    },
    permissionOrderPickup () {
      return (
        this.permissionUpdateOrderMarkersByRole ||
        (this.permissionUpdateOrder && this.userIsOrderCreatorOrManager)
      )
    },

    userIsOrderCreatorOrManager () {
      switch (this.currentUserId) {
        case this.order?.manager?.id:
        case this.order?.user?.id:
          return true
      }
      return false
    },
    userIsOrderPositionResponsible () {
      if (
        this.currentUserRole === 'production-manager' &&
        this.order?.positions?.length
      ) {
        for (const position of this.order.positions) {
          if (this.currentUserId === position.responsible_id) {
            return true
          }
        }
      }
      return false
    },

    orderManager () {
      return getUserFullName(this.order.manager)
    },
    orderTechManager () {
      return getUserFullName(this.order.tech_manager)
    },

    visibleOrderNote () {
      return (
        !!this.order.note_to ||
        !!this.order.note_manufacture ||
        !!this.order.note_otk ||
        !!this.order.note_technologist
      )
    },

    isOrderTab () {
      return this.activeTabId === this.tabs[0].id
    },

    isPackingTab () {
      return this.activeTabId === this.tabs[1].id
    },

    tabs () {
      const tabs = this.tabsDefault
      tabs[1].countPacking = this.packingCount
      return tabs
    }
  },

  created () {
  },

  destroyed () {
    this.$store.commit('waybill/RESET_STATE')
  },

  methods: {
    ...mapActions('orderDetail', [
      'updateOrderMarkers',
      'restoreOrder',
      'packingMassDelete'
    ]),

    async submitReadyForShipment () {
      this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

      await this.updateOrderMarkers({
        id: this.order.id,
        is_pickup: this.isPickup,
        documents_are_ready: this.isDocumentsAreReady,
        callback: this.closeModal
      })
      this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
    },

    openModalReadyForShipment () {
      this.syncModalReadyForShipmentFormData()
      this.openModal('modal-shipment')
    },

    syncModalReadyForShipmentFormData () {
      this.isPickup = this.order.is_pickup
      this.isDocumentsAreReady = this.order.documents_are_ready
    },

    setActiveTab (id) {
      this.activeTabId = id
    },

    selectRowsPacking (val) {
      this.selectedRowsPacking = val
    },

    // Packing
    async packingDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const data = {
          ids: getValuesArray(this.selectedRowsPacking)
        }
        await this.packingMassDelete(data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
.info-order {
  &__flex {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1100px) {
      display: grid;
      gap: 50px;
      grid-template-columns: repeat(3, 1fr);
    }

    &-col {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &__note {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }
}

.order-info-tabs {
  .tab-item:not(:last-child) {
    margin-right: 30px;
  }

  .tabs-swiper__container {
    margin-right: 0;
  }

  .tab-item__text {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }

  .tabs-swiper__btn {
    display: none;
  }
}
</style>
