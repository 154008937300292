import { Handbook } from '@/classes/Handbook'

export const HANDBOOK_LIST = {
  'delay-reason': new Handbook({
    pageTitle: 'Причины просрочки заказа',
    name: 'delay-reason',
    vuexModuleNamespaced: 'delayReason'
  }),
  workshop: new Handbook({
    pageTitle: 'Цех производства',
    name: 'workshop'
  }),
  'winding-method': new Handbook({
    pageTitle: 'Способ навивки',
    name: 'winding-method',
    vuexModuleNamespaced: 'windingMethod'
  }),
  'user-department': new Handbook({
    pageTitle: 'Отделы',
    name: 'user-department',
    vuexModuleNamespaced: 'userDepartment'
  }),
  'user-post': new Handbook({
    pageTitle: 'Должности',
    name: 'user-post',
    vuexModuleNamespaced: 'userPost'
  }),
  machine: new Handbook({
    pageTitle: 'Нормы нагрузки',
    name: 'machine'
  }),
  'consignment-stage': new Handbook({
    pageTitle: 'Этапы',
    name: 'consignment-stage',
    vuexModuleNamespaced: 'consignmentStage'
  }),
  material: new Handbook({
    pageTitle: 'Материалы',
    name: 'material'
  }),
  'reclamation-inner-reason': new Handbook({
    pageTitle: 'Причины рекламации (внутренней)',
    name: 'reclamation-inner-reason',
    vuexModuleNamespaced: 'reclamationInnerReason'
  }),
  'reclamation-outer-reason': new Handbook({
    pageTitle: 'Причины рекламации (внешней)',
    name: 'reclamation-outer-reason',
    vuexModuleNamespaced: 'reclamationOuterReason'
  }),
  'reason-treatment': new Handbook({
    pageTitle: 'Причины обращения',
    name: 'reason-treatment',
    vuexModuleNamespaced: 'reasonTreatment'
  }),
  'surface-treatment': new Handbook({
    pageTitle: 'Поверхностная обработка',
    name: 'surface-treatment',
    vuexModuleNamespaced: 'surfaceTreatment'
  }),
  test: new Handbook({
    pageTitle: 'Испытание',
    name: 'test',
    vuexModuleNamespaced: 'test'
  }),
  coating: new Handbook({
    pageTitle: 'Покрытие',
    name: 'coating'
  }),
  'winding-type': new Handbook({
    pageTitle: 'Тип навивки',
    name: 'winding-type',
    vuexModuleNamespaced: 'windingType'
  }),
  chamfer: new Handbook({
    pageTitle: 'Фаска',
    name: 'chamfer'
  }),
  flatness: new Handbook({
    pageTitle: 'Плоскостность',
    name: 'flatness'
  }),
  'winding-direction': new Handbook({
    pageTitle: 'Направление навивки',
    name: 'winding-direction',
    vuexModuleNamespaced: 'windingDirection'
  }),
  'hook-orientation': new Handbook({
    pageTitle: 'Ориентация зацепов',
    name: 'hook-orientation',
    vuexModuleNamespaced: 'hookOrientation'
  }),
  'reference-turn': new Handbook({
    pageTitle: 'Опорные витки',
    name: 'reference-turn',
    vuexModuleNamespaced: 'referenceTurn'
  }),
  'hook-type': new Handbook({
    pageTitle: 'Типы зацепов',
    name: 'hook-type',
    vuexModuleNamespaced: 'hookType'
  }),
  fold: new Handbook({
    pageTitle: 'Загибы',
    name: 'fold'
  }),
  country: new Handbook({
    pageTitle: 'Страна',
    name: 'country'
  }),
  'coating-color': new Handbook({
    pageTitle: 'Цвет покрытия',
    name: 'coating-color',
    vuexModuleNamespaced: 'coatingColor'
  }),
  'product-type': new Handbook({
    pageTitle: 'Вид продукции',
    name: 'product-type',
    vuexModuleNamespaced: 'productType'
  }),
  'tech-brand': new Handbook({
    pageTitle: 'Марка техники',
    name: 'tech-brand',
    vuexModuleNamespaced: 'techBrand'
  }),
  'tech-type': new Handbook({
    pageTitle: 'Типы техники',
    name: 'tech-type',
    vuexModuleNamespaced: 'techType'
  }),
  'tab-table-time-and-load': new Handbook({
    pageTitle: 'Табы таблицы сроков и загрузки',
    name: 'tab-table-time-and-load',
    vuexModuleNamespaced: 'tabTableTimeAndLoad'
  }),
  gost: new Handbook({
    pageTitle: 'ГОСТ',
    name: 'gost'
  }),
  'type-packaging': new Handbook({
    pageTitle: 'Вид упаковки',
    name: 'type-packaging',
    vuexModuleNamespaced: 'typePackaging'
  })
}
