<template>
  <div>
    <h1 class="mb-30">Аналитика</h1>

    <VSelect
        label="Тип отчета:"
        v-model="selectedReport"
        class="mb-30"
        width="400px"
        :allow-empty="false"
        :options="reportTypeList"
    />

    <!--  TODO FILTER -->
    <VForm
      v-model="filterData"
      class="box mb-30"
      ref="filter"
      @request="filterSubmit"
    >
      <div class="filter__input mb-30">
        <!--  1 column    -->
        <div class="d-flex flex-col gap-s">
          <VInputDate
            label="Выберите период отчета"
            ref="filterDate"
          />

          <VSelect
            v-if="filters.isOrderType"
            label="Тип заказа:"
            name="order_type"
            :options="propertiesAll.order_type"
            multiple
          />

          <VSelect
            v-if="filters.isResponsible"
            label="Ответственный:"
            :name="selectNameResponsible"
            :options="responsibleList"
            searchable
            multiple
          />

          <VSelect
            v-if="filters.isMaterial"
            label="Марка стали:"
            name="material"
            :options="propertiesAll.material"
            multiple
          />

          <VSelect
            v-if="filters.isDelayReason"
            label="Причина просрочки:"
            name="delay_reason"
            :options="propertiesAll['delay-reasons']"
            multiple
          />

          <VSelect
            v-if="filters.isTypePacking"
            label="Вид тары:"
            name="package_type"
            :options="getPackageTypes"
            searchable
            multiple
          />
        </div>

        <!--  2 column    -->
        <div class="d-flex flex-col gap-s">
          <VSelect
            v-if="filters.isSpringType"
            label="Тип пружины:"
            name="type"
            :options="propertiesAll.product_type"
            multiple
          />

          <VSelect
            v-if="filters.isWorkshop"
            label="Цех производства:"
            name="workshop"
            multiple
            :options="workshopOptions"
            @change='setWorkshopSelected'
          />

          <VSelect
            v-if="filters.isApplicationArea"
            label="Область применения:"
            name="application_area"
            :options="propertiesAll.application_area"
            multiple
          />

          <VSelect
            v-if="filters.isStage"
            label="Этап:"
            name="stage"
            :options="propertiesAll.status"
            multiple
          />

          <VSelect
            v-if="filters.isExecutor"
            label="Исполнитель:"
            name="executor"
            :options="executorList"
            searchable
            multiple
          />

          <VSelect
            v-if="filters.isReclamationType"
            label="Тип отчета:"
            name="report_type"
            :options="typeOptions"
          />
          <VSelect
            v-if="filters.isPacker"
            label="Номер упаковщика:"
            name="packers"
            item-text="packer"
            :options="getPackagePackers"
            searchable
            multiple
          />
        </div>

        <div class="d-flex flex-col gap-s">
            <VSelect
                v-if="filters.isShape"
                label="Вид пружины"
                name="shape"
                :options="shapeAll"
                multiple
                isTooltip
                tooltipText="Данная фильтрация показывает актуальные значения от 11.04.24"
            />

            <VSelect
                v-if="filters.isMethodWinding"
                label="Способ навивки:"
                name="winding_method"
                :options="windingMethodOptionsFiltered"
                multiple
            />
        </div>
      </div>

      <VButton
        text="Сформировать"
        type="submit"
        color="primary"
        :loading="isLoading"
      />
    </VForm>

    <Spinner v-if="isLoading"/>

    <div v-else-if="items.length">
      <div v-if="hasChart" class="d-flex mb-30 gap-l">
        <VCheckbox
          v-model="isTable"
          label="Таблица"
          :disabled="!hasChart"
        />

        <VCheckbox
          v-model="isChart"
          label="График"
        />
      </div>

      <div class="between ai-c mb-20">
        <h3>{{ selectedReport.name }}</h3>

        <div class="buttons">
          <VButton
            text="На печать"
            icon="print"
            size="sm"
            @click.native="openModal('print')"
          />

          <download-excel
            v-if="isTable"
            :name="selectedReport.name"
            :header="excelHeader"
            :fields="excelFields"
            :fetch="getExcelData"
          >
            <VButton
              text="Экспорт в XLS"
              icon="export"
              size="sm"
              :loading="isLoadingExcel"
            />
          </download-excel>
        </div>
      </div>

      <!--  Table  -->
      <div v-if="isTable" id="printTable" class="mb-30">
        <ReportFilterInfo
          v-if="visibleReportFilterInfo"
          class="absolute hidden mb-30"
          :title="selectedReport.name"
          :date="reportFilter.date"
          :filters="reportFilter.filters"
        />

        <ReclamationInnerTable
          v-if="isReportReclamationTypeNotLoss"
          :rows="items"
          :columns-report="columns"
        />

        <AppTable
          v-else
          ref="table"
          :class="selectedReport.table.classes"
          :columns-before="selectedReport.table.columnsBefore || []"
          :columns="columns"
          :handler-columns="selectedReport.table.handlerColumns"
          :items="items"
          :tree-children-keys="selectedReport.table.treeKeys"
          :is-tree-table="selectedReport.table.isTreeTable"
          :is-open-tree="isOpenTableTree"
          :row-active="selectedReport.table.isRowActive"
          @rowClick="onTableRow"
          withTotal
        />
      </div>

      <!--  Chart  -->
      <div v-if="isChart" id="printChart">
        <ReportFilterInfo
          v-if="visibleReportFilterInfo"
          class="mb-30"
          :title="selectedReport.name"
          :date="reportFilter.date"
          :filters="reportFilter.filters"
        />

        <RadioGroup
          v-if="visibleChartRadio"
          class="radio-group--row mb-20"
          label="Выберите тип графика"
          :options="currentReport.chartRadioOptions"
          :value="activeChartRadio"
          @input="changeActiveChartRadio"
        />

        <BarChart
          chart-id="chart_bar"
          class="mb-30"
          :chartData="chartData"
        />
      </div>
    </div>

  <h3 v-else-if="isNotFound">Ничего не найдено</h3>

  <ModalPrint
    v-if="isModal === 'print'"
    :print-options="printOptions"
    init-print-type="table"
    @request="toPrint"
    @reject="closeModal"
  />

  <!-- неизвестная модалка отключена, была по клику на строку в таблице @rowClick="onTableRow" -->
  <BaseModal
    v-if="isModal === 'table'"
    :title="selectedReport.modal ? selectedReport.modal.title : ''"
    class="modal-changelog"
    @close="closeModalTable"
  >
      <AppTable
        class="modal__table modal__box custom-scroll"
        :columns="selectedReport.modal.columns"
        :items="modalTable.items"
      />
    </BaseModal>
  </div>
</template>

<script>
  import VInputDate from '@/components/Form/VInputDate'
  import AppTable from '@/components/ui/AppTable'
  import BaseModal from '@/components/ui/Modal/BaseModal'
  import BarChart from '@/shared/ui/Charts/BarChart.vue'
  import VForm from '@/components/Form/VForm'
  import ModalPrint from '@/components/ui/Modal/ModalPrint'
  import RadioGroup from '@/components/Form/Radio/RadioGroup'
  import ReportFilterInfo from '@/components/views/Analytics/ReportFilterInfo'
  import VButton from '@/components/simple/button/VButton'
  import VCheckbox from '@/components/Form/VCheckbox'
  import VSelect from '@/components/Form/VSelect'
  import ReclamationInnerTable from '@/components/views/Analytics/ReclamationInnerTable'

  import { mixinModal } from '@/mixins/modal/mixinModal'
  import { mixinIsLoading } from '@/mixins/mixinIsLoading'

  import { getAll } from '@/api/request'
  import { mapGetters, mapState, mapMutations } from 'vuex'
  import { getUserListByRole } from '@/utils/model/User'
  import {
    ANALYTICS_PRINT_TYPE_LIST,
    REPORTS_TYPE_DATA,
    RECLAMATION_INNER_REPORT_TYPE
  } from '@/const/analytics'
  import getValuesArray from '@/utils/array/getValuesArray'
  import { setAlertError } from '@/utils/store/alert'
  import { formatDate } from '@/utils/format/date'
  import { map2MockId } from '@/utils/array'
  import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
  import { transformDateForSend } from '@/utils/form/date'
  import { getChartDatasets } from '@/utils/chart'
  /* import { CHART_DATASETS, CHART_DATASETS_STYLES } from '@/const/chart' */
  import { mapReportWireConsumptionTableData } from '@/utils/map/mapReportWireConsumptionTableData'
  import { getHTML2canvasDataURL } from '@/utils/html2canvasLib'
  import { mixinWorkshopAndWindingMethodOptions } from '@/mixins/views/mixinWorkshopAndWindingMethodOptions'
  import { mapChartReportTypeDecision } from '@/utils/map/mapChartReportTypeDecision'

  const LABELS = {
    application_area: 'Область применения',
    delay_reason: 'Причина просрочки',
    executor: 'Исполнитель',
    inner_reclamation_type: 'Причина рекламации',
    material: 'Марка стали',
    order_type: 'Тип заказа',
    responsible: 'Ответственный',
    stage: 'Этап',
    tech_manager: 'Ответственный',
    type: 'Тип пружины',
    winding_method: 'Способ навивки',
    workshop: 'Цех производства',
    report_type: 'Тип отчета',
    package_type: 'Вид тары',
    packers: 'Номер упаковщика'
  }
  /* const datasetOptions = {
    ...CHART_DATASETS_STYLES,
    ...CHART_DATASETS[0]
  } */

  export default {
    name: 'AnalyticsView',
    components: {
      ReclamationInnerTable,
      AppTable,
      BaseModal,
      ReportFilterInfo,
      ModalPrint,
      RadioGroup,
      VButton,
      VCheckbox,
      BarChart,
      VInputDate,
      VSelect,
      VForm
    },
    mixins: [mixinModal, mixinIsLoading, mixinWorkshopAndWindingMethodOptions],
    data () {
      return {
        // Выбор отчета
        reportTypeList: REPORTS_TYPE_DATA,
        filters: {},
        perPage: 20,
        currentReport: null,
        isNotFound: false,

        filterData: {},

        // Таблица
        isOpenTableTree: false,
        isTable: true,
        items: [],
        columns: [],

        // График
        activeChartRadio: '',
        isChart: true,
        chartData: {
          labels: [],
          datasets: []
        },
        multiChartData: {},

        // Печать
        currentFilter: {},
        visibleReportFilterInfo: false,
        reportFilter: {
          date: '',
          filters: []
        },

        // Excel
        isLoadingExcel: false,
        excelHeader: [],

        modalTable: {
          items: []
        }
      }
    },

    computed: {
      ...mapState('users', {
        users: 'all'
      }),
      ...mapGetters('users', {
        executorList: 'executorList',
        stageResponsibleList: 'stageResponsibleList'
      }),
      ...mapGetters({
        propertiesAll: 'records/propertiesAll',
        shapeAll: 'records/shapeAll',
        getSelectedReport: 'records/getSelectedReport',
        isResetReport: 'records/isResetReport',
        getPackageTypes: 'records/getPackageTypes',
        getPackagePackers: 'records/getPackagePackers'
        // внутренняя рекламация
        /* reclamationInnerList: 'handbook/reclamationInnerReason/getList' */
      }),

      responsibleList () {
        switch (this.selectedReport.value) {
           /* case 'reclamation-inner': {
             return this.responsibleListForReclamationIn
           } */
          case 'stage': return this.stageResponsibleList
          default: return this.users
        }
      },
      responsibleListForReclamationIn () {
        return getUserListByRole(this.users, [6, 7, 8, 9, 10])
      },

      selectNameResponsible () {
        switch (this.selectedReport.value) {
          case 'launched-orders-technical-department': return 'tech_manager'
          default: return 'responsible'
        }
      },
      url () {
        return 'analytics/' + this.currentReport.value
      },
      visibleChartRadio () {
        return this.currentReport.value === 'reclamation-inner'
      },

      printOptions () {
        if (!this.hasChart) {
          return ANALYTICS_PRINT_TYPE_LIST.slice(0, 1)
        }
        return ANALYTICS_PRINT_TYPE_LIST
      },

      typeOptions () {
        return RECLAMATION_INNER_REPORT_TYPE
      },

      excelFields () {
        let fields = {}

        if (this.selectedReport?.excel?.handledFields) {
          this.columns.forEach(item => {
            const title = this.selectedReport.excel.handledFields(item.title, item.name)

            fields = {
              ...fields,
              [title]: item.name
            }
          })
        } else {
          this.columns.forEach(item => {
            fields = {
              ...fields,
              [item.title]: item.name
            }
          })
        }

        return fields
      },

      hasChart () {
        switch (this.selectedReport.value) {
          case 'overdue-orders':
          case 'stage':
          case 'wire-consumption': return false
          case 'reclamation-inner-by-type': {
            return this.currentFilter?.report_type?.value !== 'loss'
          }
          default: return true
        }
      },

      pagesForReportStage () {
        return this.getCountPagesBy(this.sizeUsersForReportStage)
      },
      sizeUsersForReportStage () {
        return this.users.length
      },

      selectedReport: {
        get () {
          return this.getSelectedReport
        },
        set (data) {
          this.SET_SELECTED_REPORT(data)
          this.SET_IS_RESET_RECORD(false)
        }
      },
      isReportReclamationTypeNotLoss () {
        return this.isReclamationInnerReport && this.currentFilter.report_type.value !== 'loss'
      },
      isReclamationInnerReport () {
        return this.selectedReport.value === 'reclamation-inner-by-type'
      },
      isPackagesReport () {
        return this.selectedReport.value === 'packages'
      }
    },

    watch: {
      isTable () {
        if (!this.isTable) {
          this.isChart = true
        }
      },
      isChart () {
        if (!this.isChart) {
          this.isTable = true
        }
      },
      isResetReport (val) {
        if (val) this.changeReport(this.selectedReport)
      },
      selectedReport (data) {
        this.changeReport(data)
      }
    },

    mounted () {
      this.changeReport(this.selectedReport)
      /* this.$store.dispatch('handbook/reclamationInnerReason/loadList') */
    },

    beforeDestroy () {
      this.filters = {}
      this.$refs.filterDate.$refs.dateRef._flatpickr.clear()
      this.items = []
      this.columns = []

      this.resetChartData()
    },

    methods: {
      ...mapMutations({
        SET_SELECTED_REPORT: 'records/SET_SELECTED_REPORT',
        SET_IS_RESET_RECORD: 'records/SET_IS_RESET_RECORD',
        SET_INFO_SELECTED_REPORT: 'records/SET_INFO_SELECTED_REPORT'
      }),

      changeReport (data) {
        this.isNotFound = false
        this.resetFilter()
        this.items = []
        this.columns = []
        this.resetChartData()
        this.resetReportFilterInfo()

        this.isChart = this.hasChart

        this.filters = { ...data.filters }
        this.columns = [...data.table.columns]

        this.currentReport = data
      },

      resetFilter () {
        this.filters = {}
        this.currentFilter = {}
        this.$refs.filter.clear()
        this.$refs.filterDate.$refs.dateRef._flatpickr.clear()
      },

      async filterSubmit (data) {
        let params = { ...data }

        this.isNotFound = false

        const dates = transformDateForSend(this.$refs.filterDate.$refs.dateRef?.defaultValue)
        params = { ...dates, ...params }

        this.currentFilter = { ...params }

        const keys2Ids = [
          'workshop',
          'application_area',
          'delay_reason',
          'executor',
          'inner_reclamation_type',
          'material',
          'order_type',
          'stage',
          'tech_manager',
          'type',
          'winding_method',
          'responsible',
          'shape',
          'package_type'
        ]
        params = prepareFormDataIdArray(params, keys2Ids)

        if (params?.report_type) {
          params.type = params.report_type.value
          delete params.report_type
        }

        if (params?.packers) {
          params.packers = getValuesArray(params.packers, 'packer')
        }

        await this.loadReport(params)

        this.SET_IS_RESET_RECORD(false)
      },

      async loadReport (params) {
        this.startLoading()
        this.isNotFound = false
        this.items = []
        this.resetChartData()

        try {
          const res = this.currentReport.value === 'stage' ?
            await this.getReportStage(params)
            : await getAll(this.url, params)

          const hasItems = res.data?.items?.length || res.data?.table?.length || res.data?.length
          if (!hasItems) {
            this.isNotFound = true
            return
          }

          switch (this.currentReport.value) {
            case 'wire-consumption': {
              this.items = mapReportWireConsumptionTableData(res.data.items)
              break
            }
            case 'reclamation-inner-by-type': {
              this.changeInfoReclamation()
              this.items = res.data
              if (this.currentFilter.report_type.value === 'loss') {
                this.items = mapReportWireConsumptionTableData(this.items)
                this.isChart = false
              }

              if (this.currentReport.chart) {
                const chartLabels = getValuesArray(this.items, this.currentReport.chart.labels) || []
                this.chartData.labels = this.currentFilter.report_type.value === 'type' ? mapChartReportTypeDecision(chartLabels) : chartLabels

                this.chartData.datasets = getChartDatasets(this.items, this.currentReport.chart.datasets)
              }
              break
            }
            case 'packages': {
              this.items = res.data

              if (this.currentReport.chart) {
                this.chartData.labels = getValuesArray(this.items, this.currentReport.chart.labels) || []
                this.chartData.datasets = getChartDatasets(this.items, this.currentReport.chart.datasets)
              }
              break
            }
            default: {
              this.items = res.data.items

              if (this.currentReport.chart) {
                this.chartData.labels = getValuesArray(this.items, this.currentReport.chart.labels) || []
                this.chartData.datasets = getChartDatasets(this.items, this.currentReport.chart.datasets)
              }
            }
          }
        } catch (e) {
          await setAlertError(e, 'Не удалось загрузить отчет')
        } finally {
          this.finishLoading()
        }
      },
      async getReportStage (params) {
        const pages = this.getCurrentPages(params)

        const arrayForMap = Array.from({ length: pages })

        const response = await Promise.all(arrayForMap.map((_, i) => getAll(this.url, {
          ...params,
          page: i + 1,
          'per-page': this.perPage
        })))

        const mergedResponse = {
          data: {
            items: []
          }
        }

        response.forEach(res => {
          mergedResponse.data.items.push(...res.data.items)
        })

        return mergedResponse
      },

      getCurrentPages (params) {
        const userIdsSelected = []

        if (params?.executor_ids) {
          userIdsSelected.push(...params.executor_ids)
        }
        if (params?.responsible_ids) {
          userIdsSelected.push(...params.responsible_ids)
        }

        if (userIdsSelected.length) {
          const size = this.getCountUsersBy(userIdsSelected)
          return this.getCountPagesBy(size)
        }

        return this.pagesForReportStage
      },

      getCountUsersBy (ids) {
        const setIds = new Set(ids)
        return setIds.size
      },
      getCountPagesBy (size) {
        const count = size / this.perPage
        return Math.ceil(count)
      },

      changeActiveChartRadio (value) {
        this.activeChartRadio = value

        switch (value) {
          case 'date': {
            let labels = []
            for (const year in this.multiChartData.date) {
              labels = [...labels, ...Object.keys(this.multiChartData.date[year]).map(key => `${key}.${year}`)]
            }
            let data = []
            for (const year in this.multiChartData.date) {
              data = [...data, ...Object.values(this.multiChartData.date[year])]
            }
            this.chartData.labels = labels || []
            this.chartData.datasets[0].data = data || []
            break
          }
          case 'reason': {
            this.chartData.labels = Object.keys(this.multiChartData.reason) || []
            this.chartData.datasets[0].data = Object.values(this.multiChartData.reason) || []
            break
          }
          case 'responsible': {
            this.chartData.labels = Object.keys(this.multiChartData.person) || []
            this.chartData.datasets[0].data = Object.values(this.multiChartData.person) || []
          }
        }
      },

      async toPrint (data) {
        try {
          const type = data.type
          this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

          this.setReportFilterInfo()
          this.toggleVisibleFilterInfo()
          this.toggleIsOpenTableTree()

          if (type === 'table') {
            await this.printTable()
          }

          if (type === 'chart') {
            await this.printChart()
          }
        } catch (e) {
          await setAlertError(e, 'Не удалось подготовить данные для печати')
        } finally {
          this.toggleVisibleFilterInfo()
          this.toggleIsOpenTableTree()
          this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
          this.closeModal()
        }
      },
      async printChart () {
        const element = window.document.getElementById('printChart')

        const src = await getHTML2canvasDataURL(element)

        try {
          const win = window.open()
          await win.document.write(`<br><img style="max-width: 100vw" src="${src}"/>`)
          win.print()
        } catch (e) {
          await setAlertError(e, 'Всплывающее окно заблокировано браузером')
        }
      },
      async printTable () {
        return new Promise(resolve => {
          setTimeout(() => {
            this.$htmlToPaper('printTable')
            resolve()
          }, 0)
        })
      },
      toggleVisibleFilterInfo () {
        this.visibleReportFilterInfo = !this.visibleReportFilterInfo
      },
      toggleIsOpenTableTree () {
        this.isOpenTableTree = !this.isOpenTableTree
      },

      resetChartData () {
        this.chartData.labels = []
        this.chartData.datasets = []
        this.multiChartData = {}
      },

      async getExcelData () {
        try {
          this.isLoadingExcel = true
          await this.$store.dispatch('alert/setAlert', { type: 'warning', message: 'Началась подготовка отчета, пожалуйста, подождите' })

          this.setExcelHeader()

          if (this.isReportReclamationTypeNotLoss) {
            return this.selectedReport.excel.getMapped(this.items)
          }

          const totalItem = {
            [this.columns[0].name]: 'Итого',
            ...this.$refs.table?.$refs?.total?.totalItem
          }

          if (this.isPackagesReport) {
            const rows = this.selectedReport.excel.handlerRows(this.items)
            return [...rows, totalItem]
          }

          return [...this.items, totalItem]
        } catch (error) {
          await setAlertError(error, 'Не удалось подготовить данные для экспорта')
        } finally {
          this.isLoadingExcel = false
        }
      },
      setExcelHeader () {
        this.setReportFilterInfo()

        const headers = [this.selectedReport.name, ' ']
        if (this.reportFilter.date) {
          headers.push(this.reportFilter.date, ' ')
        }

        const filters = []
        for (const item of this.reportFilter.filters) {
          const values = Array.isArray(item.text) ? item.text.join('; ') : item.text
          filters.push(`${item.label}: ${values}`)
        }
        if (filters.length) {
          headers.push(...filters, ' ')
        }

        this.excelHeader = headers
      },

      // Установка фильтра для печати
      setReportFilterInfo () {
        this.reportFilter.date = this.getReportDates()
        const filters = []

        for (const filterName in this.currentFilter) {
          if (['date_start', 'date_finish'].includes(filterName)) continue
          if (!this.currentFilter[filterName]) continue
          filters.push({
            label: LABELS[filterName],
            text: this.getFilterText(filterName)
          })
        }
        this.reportFilter.filters = map2MockId(filters)
      },
      getFilterText (filterName) {
        switch (filterName) {
          case 'report_type': return [this.currentFilter[filterName].name]
          case 'packers': return getValuesArray(this.currentFilter[filterName], 'packer')
          default: return getValuesArray(this.currentFilter[filterName], 'name')
        }
      },
      getReportDates () {
        const dateStart = this.currentFilter?.date_start
        if (!dateStart) return ''

        const start = formatDate(dateStart)
        const finish = this.currentFilter.date_finish !== dateStart ? ` - ${formatDate(this.currentFilter.date_finish)}` : ''
        return start + finish
      },
      resetReportFilterInfo () {
        this.reportFilter.date = ''
        this.reportFilter.filters = []
      },

      onTableRow (item) {
        if (this.selectedReport.modal) {
            this.modalTable.items.push(item)
            this.openModal('table')
        }
      },
      closeModalTable () {
        this.closeModal()
        this.modalTable.items.length = 0
      },
      changeInfoReclamation () {
        if (!this.currentFilter?.report_type) return
        const table = this.currentFilter.report_type?.table
        const chart = this.currentFilter.report_type?.chart
        const excel = this.currentFilter.report_type?.excel

        this.columns = [...table.columns]
        this.SET_INFO_SELECTED_REPORT({ table, chart, excel })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter__input {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 400px));
    column-gap: 25px;
  }
</style>
