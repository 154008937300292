<template>
  <ModalForm
    v-model="formData"
    title="Для создания позиции необходимо заполнить поле заказа «Инженер технолог»"
    class="modal--title-reset"

    text-btn-confirm="Применить"
    btn-size="xl"
    only-btn-submit

    @request="techManagerFillHandler"
    @reject="onReject"
  >
    <VSelect
      label="Инженер-технолог:"
      name="tech_manager"
      class="mb-20"
      required
      :options="processEngineerList"
    >
      <Validation for="required"/>
    </VSelect>
  </ModalForm>
</template>

<script>

import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import Validation from '@/components/Form/Validation.vue'
import VSelect from '@/components/Form/VSelect.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalFillTechManager',
  components: {
    VSelect,
    Validation,
    ModalForm
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    formData: {
      tech_manager: ''
    }
  }),

  computed: {
    ...mapGetters('users', {
      processEngineerList: 'processEngineerList'
    })
  },

  methods: {
    ...mapActions({
      updateOrder: 'order/updateOrder'
    }),

    async techManagerFillHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const postData = {
          id: this.order.id,
          account_number: this.order.account_number,
          client_name: this.order.client_name,
          city: this.order.city,
          date_order: this.order.date_order,
          tech_manager_id: data.tech_manager.id ?? null
        }
        const response = await this.updateOrder(postData)
        this.$emit('fillTechManager', response)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
