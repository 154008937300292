<template>
  <div>
    <section class="section page__header">
      <VButtonPageBack class="mb-30" />

      <div class="d-flex as-c jc-sb">
        <h1>{{ pageTitle }}</h1>

        <VButton
          :text="'Создать ' + textItem"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="openModal(null)"
        />
      </div>
    </section>

    <Spinner v-if="isLoading" />

    <section v-else class="section page__content">
      <Container
        v-if="list.length"
        class="handbook__list column gap-s"
        drag-class="grabbing"
        :get-child-payload="getDropPayload"
        @drop="postSortedList"
      >
        <Draggable
          v-for="card in list"
          :key="card.id"
          class="grab"
        >
          <APCard
            :title="card.name"
            @update="openModal(card)"
            @remove="openRemoveConfirm(card.id)"
          >
            <div
              v-if="card.data && card.data.length"
              class="handbook__info-list"
            >
              <AppInfoTextBlock
                v-for="(item, index) in card.data"
                :key="index"
                :label-text="item.name"
                :text="item.value"
                :length="60"
              />
            </div>
          </APCard>
        </Draggable>
      </Container>

      <div class="page__no-records" v-else>
        Список пуст
      </div>

      <SpinnerOverlay v-if="$store.state.isLoadingDnD" />
    </section>

    <transition name="fade">
      <ModalHandbook
        v-if="modal.active"
        :title="textItem"
        :state="modal"
        :inputs="modalInputList"
        @request="saveItem"
        @close="closeModal"
      />

      <ModalConfirmation
        v-if="removeConfirm.active"
        title="Скрыть выбранный элемент?"
        @confirm="removeHandler"
        @reject="closeRemoveConfirm"
      />
    </transition>
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock'
import APCard from '@/components/views/AdminPanel/APCard'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import ModalHandbook from '@/components/views/AdminPanel/Handbook/ModalHandbook'
import SpinnerOverlay from '@/components/ui/SpinnerOverlay'
import VButton from '@/components/simple/button/VButton'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import { Container, Draggable } from 'vue-smooth-dnd'
import { HANDBOOK_LIST } from '@/const/handbookList'

export default {
  name: 'HandbookUpdate',
  components: {
    AppInfoTextBlock,
    APCard,
    Container,
    Draggable,
    ModalConfirmation,
    ModalHandbook,
    SpinnerOverlay,
    VButton,
    VButtonPageBack
  },
  props: {
    handbookName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    removeConfirm: {
      active: false,
      id: null
    }
  }),
  computed: {
    currentVuexModule () {
      return 'handbook/' + this.currentHandbook.vuexModuleNamespaced + '/'
    },

    list () {
      return this.$store.getters[this.currentVuexModule + 'getList']
    },
    modal () {
      return this.$store.getters[this.currentVuexModule + 'getModal']
    },
    isLoading () {
      return this.$store.getters[this.currentVuexModule + 'getIsLoading']
    },

    pageTitle () {
      return this.currentHandbook.pageTitle
    },

    textItem () {
      switch (this.handbookName) {
        case 'chamfer': return 'фаску'
        case 'coating': return 'покрытие'
        case 'delay-reason': return 'причину'
        case 'department': return 'отдел'
        case 'flatness': return 'плоскостность'
        case 'fold': return 'загиб'
        case 'hook-orientation': return 'ориентацию'
        case 'machine': return 'норму'
        case 'material': return 'материал'
        case 'post': return 'должность'
        case 'reclamation-inner':
        case 'reclamation-outer': return 'причину'
        case 'reference-turn': return 'виток'
        case 'stage': return 'этап'
        case 'treatment': return 'обработку'
        case 'test': return 'испытание'
        case 'winding-direction': return 'направление'
        case 'winding-method': return 'способ'
        case 'country': return 'страну'
        case 'coating-color': return 'цвет'
        case 'tech-brand': return 'бренд'
        case 'production-type':
        case 'tech-type':
        case 'hook-type':
        case 'winding-type': return 'тип'
        case 'workshop': return 'цех'
        case 'type-packaging': return 'вид упаковки'
        default: return ''
      }
    },
    modalInputList () {
      switch (this.handbookName) {
        case 'machine': return { vendorCode: false, performance: true, startTime: true }
        case 'material': return { vendorCode: true, hardness: true, density: true }
        case 'consignment-stage': return { types: true }
        case 'coating-color': return { coating: true }
        case 'tab-table-time-and-load':
        case 'winding-method': return { workshop: true }
        case 'gost': return { material: true, surface_treatment: true }
        case 'type-packaging': return { dimensions: true, weight: true }
        default: return {}
      }
    },
    currentHandbook () {
      return HANDBOOK_LIST[this.handbookName]
    }
  },

  watch: {
    '$route.params.handbookName': {
      async handler () {
        await this.loadList()
      },
      immediate: true
    }
  },

  methods: {
    // vuex mutations
    openModal (item) {
      return this.$store.commit(this.currentVuexModule + 'OPEN_MODAL', item)
    },
    closeModal () {
      return this.$store.commit(this.currentVuexModule + 'CLOSE_MODAL')
    },

    // vuex actions
    async loadList () {
      return this.$store.dispatch(this.currentVuexModule + 'loadList')
    },

    async postSortedList (result) {
      try {
        this.$store.commit('START_IS_LOADING_DND')
        await this.$store.dispatch(this.currentVuexModule + 'postSortedList', result)
      } finally {
        this.$store.commit('FINISH_IS_LOADING_DND')
      }
    },

    async saveItem (data) {
      return this.$store.dispatch(this.currentVuexModule + 'saveItem', data)
    },

    async hideItem (id, hidden) {
      return this.$store.dispatch(this.currentVuexModule + 'hideItem', { id, hidden })
    },

    // page
    openRemoveConfirm (id) {
      this.removeConfirm = { active: true, id: id }
    },
    closeRemoveConfirm () {
      this.removeConfirm = { active: false, id: null }
    },

    async removeHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await this.hideItem(this.removeConfirm.id)
        this.closeRemoveConfirm()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    getDropPayload (idx) {
      return this.list[idx]
    }
  }
}
</script>
