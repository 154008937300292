import PackingApi from '@/api/production/packing/PackingApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'

const PACKING_ERRORS = {
  ...createBaseResponseErrors('упаковку', 'упаковок')
}

export default {
  ...createBaseController(PackingApi, PACKING_ERRORS)
}
