import { getAll } from '@/api/request'
import { setAlertError, showAlertError } from '@/utils/store/alert'

import { generateId } from '@/utils/create'
import { refreshArr } from '@/utils/array/reactive'
import { CatalogApi } from '@/api/catalog'

import { REPORTS_TYPE_DATA } from '@/const/analytics'

const state = () => ({
  paramsAll: {
    'per-page': 5000
  },

  urlProperties: 'position/property/properties',
  urlTimings: 'order/timings',

  properties: {
    application_area: [],
    chamfer: [],
    coating: [],
    color: [],
    d_wire: [],
    'delay-reasons': [],
    flatness: [],
    fold: [],
    hook_orientation: [],
    hook_type: [],
    material: [],
    order_type: [],
    product_type: [],
    reference_turn: [],
    status: [],
    status_line_template: [],
    surface_treatment: [],
    test: [],
    timing: [],
    winding_direction: [],
    winding_method: [],
    winding_type: [],
    workshop: [],
    shape: [],
    package_types: []
  },

  timingAll: [],
  articlesAll: {
    products: [],
    position_templates: []
  },
  selectedReport: REPORTS_TYPE_DATA[0],
  isResetReport: false
})

const getters = {
  propertiesAll: state => state.properties,
  dWireAll: state => {
    return state.properties.d_wire.map(item => ({
      name: item,
      id: generateId()
    }))
  },
  shapeAll: state => state.properties.shape,
  shapeType2: state => {
    return state.properties.shape.filter(item => item.type_ids.includes(2))
  },

  // order
  delayReasonAll: state => state.properties['delay-reasons'],
  timingAll: state => state.timingAll,

  // этапы
  stageList: state => {
    return state.properties.status.filter(item => ![3, 4, 5].includes(item.flag_id))
  },

  defaultPositionType: state => state.properties.product_type[0],

  timingForLoadCalendarTable (state) {
    return state.timingAll.filter(item => !isNaN(+item.name[0]))
  },
  getSelectedReport: state => state.selectedReport,
  isResetReport: state => state.isResetReport,
  getSurfaceTreatment: state => id => {
    return state.properties?.surface_treatment?.find(item => item.id === id)
  },
  getPackageTypes: state => {
    return state.properties?.package_types
  },
  getPackagePackers: state => {
    return state.properties?.package_packers
  }
}

const mutations = {
  SET_PROPERTIES_ALL: (state, payload) => {
    state.properties = Object.assign({}, state.properties, payload)
  },

  SET_TIMING_All: (state, timing) => { state.timingAll = timing },

  SET_ARTICLES_ALL: (state, data) => {
    for (const key in data) {
      refreshArr(state.articlesAll[key], data[key])
    }
  },
  SET_SELECTED_REPORT: (state, report) => {
    state.selectedReport = report
  },
  RESET_SELECTED_REPORT: (state) => {
    state.selectedReport = REPORTS_TYPE_DATA[0]
  },
  SET_IS_RESET_RECORD: (state, val) => {
    state.isResetReport = val
  },
  SET_INFO_SELECTED_REPORT: (state, { table, chart, excel }) => {
    state.selectedReport.table = table
    state.selectedReport.chart = chart
    state.selectedReport.excel = excel
  }
}

const actions = {
  async getPropertiesAll ({ state, commit }) {
    try {
      const res = await getAll(state.urlProperties)
      commit('SET_PROPERTIES_ALL', res.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить справочник')
    }
  },

  async getTimingAll ({ state, commit }) {
    try {
      const response = await getAll(state.urlTimings, state.paramsAll)
      commit('SET_TIMING_All', response.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить список сроков изготовления')
    }
  },

  async getAllArticles ({ commit }) {
    try {
      const response = await CatalogApi.getAllArticles()
      commit('SET_ARTICLES_ALL', response.data)
    } catch (e) {
      showAlertError(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
