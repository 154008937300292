<template>
  <div class="table__link-list gap-xxs">
    <router-link
      class="link link--no-line"
      target="_blank"
      :to="{ name: routerData.pageName, params: getLinkParams() }"
    >
      {{ text }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    routerData: {
      type: Object,
      required: true
    }
  },

  computed: {
    text () {
      return this.routerData.textLink + this.item[this.routerData.dataKey] + ' (' + this.routerData.textLinkSecond + this.item[this.routerData.dataKeySecond] + ')'
    }
  },

  methods: {
    getLinkParams () {
      return this.routerData.paramsKey && this.routerData.paramsValueKey ? { [this.routerData.paramsKey]: this.item[this.routerData.paramsValueKey] } : { id: this.item.id }
    }
  }
}
</script>
