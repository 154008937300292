<template>
  <div>
    <ModalPrint
      v-if="!isFilePreview"
      :init-print-type="options[0].value"
      :print-options="options"
      @request="onSubmit"
      @reject="onReject"
    />

    <ModalFilesPreview
      v-if="isFilePreview"
      :files="files"
      :init-active-file-idx="activeFileIdx"
      @close="onReject"
    />
  </div>
</template>

<script>
import ModalPrint from '@/components/ui/Modal/ModalPrint.vue'
import { PRINT_OPTIONS } from '../model/const'
import { showAlertError } from '@/utils/store/alert'
import { ReportApi } from '@/api/report/ReportApi'
import ModalFilesPreview from '@/components/files/ModalFilesPreview'
import { mapActions } from 'vuex'
import { PACKING_STATUSES } from '@/entities/order/model'

export default {
  components: {
    ModalPrint,
    ModalFilesPreview
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    orderId: {
      type: [String, Number],
      required: true
    },
    selectedRows: {
      type: Array,
      required: true
    }
  },
  computed: {
    options: () => PRINT_OPTIONS
  },
  data: () => ({
    files: [],
    activeFileIdx: 0,
    isFilePreview: false
  }),

  methods: {
    ...mapActions('orderDetail', [
      'packingMassChangeStatus'
    ]),

    async onSubmit () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
        this.files = []

        const packageIds = this.selectedRows.map(item => item.id)
        const response = await ReportApi.getBy('packages', {
          order_id: this.orderId,
          package_ids: packageIds
        })
        const file = {
          url_pdf: response.data.url,
          name: response.data.url.split('/').at(-1)
        }
        this.files.push(file)
        this.isFilePreview = true
        await this.massChangeStatus()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },
    async massChangeStatus () {
      try {
        const packageData = []
        this.selectedRows.forEach(item => {
          packageData.push({
            id: item.id,
            status: PACKING_STATUSES[0].value
          })
        })

        await this.packingMassChangeStatus(packageData)
      } catch (e) {
        showAlertError(e)
      }
    },
    onReject () {
      this.$emit('close')
    }
  }
}
</script>
