<template>
  <div>
    <AppTable
      class="table__order-packaging custom-scroll"
      :columns="columns"
      :items="packingPrepare"
      :selectable="permissionPrintPacking"
      :show-action-column="$store.getters['permission/permissionAddPacking']"
      @selectRow="selectRow"
    >
      <template #actionButton="data" v-if="$store.getters['permission/permissionAddPacking']">
        <VButton
          icon="edit"
          size="sm"
          class="btn--no-shadow"
          @click.native="openModalEdit(data.item)"
        />
      </template>
    </AppTable>

    <ModalPackaging
      v-if="isModal === 'packaging'"
      title="Редактирование упаковки"
      :packing="updatedItem"
      @reject="closeModalEdit"
    />
  </div>

</template>

<script>
import AppTable from '@/components/ui/AppTable.vue'
import { mapGetters, mapState } from 'vuex'
import { formatDate } from '@/utils/format/date'
import { handlerAmountCol, handlerPositionCol, handlerTypeCol } from '@/utils/order/packing'
import ModalPackaging from '@/components/ui/Modal/Production/ModalPackaging.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import VButton from '@/components/simple/button/VButton.vue'

export default {
  name: 'OrderInfoPackaging',
  components: {
    VButton,
    ModalPackaging,
    AppTable
  },
  mixins: [mixinModal],
  data () {
    return {
      columns: [
        {
          title: 'Позиция',
          name: 'position_id_amount',
          handlerFormat: handlerPositionCol
        },
        {
          title: 'Вид упаковки',
          name: 'package_type',
          handlerFormat: handlerTypeCol
        },
        {
          title: 'Кол-во изделий',
          name: 'position_id_amount',
          handlerFormat: handlerAmountCol
        },
        {
          title: 'Габариты (мм), вес',
          name: 'dimensions_weight'
        },
        {
          title: 'Статус',
          name: 'status_data',
          isStatus: true
        },
        {
          title: '№ упаковщика',
          name: 'packer'
        },
        {
          title: 'Дата',
          name: 'created_at',
          handlerFormat: formatDate
        }
      ],
      updatedItem: {
        type: Object,
        default: () => {}
      }
    }
  },
  computed: {
    ...mapState('orderDetail', {
      packingPrepare: 'packingPrepare'
    }),
    ...mapGetters('permission', {
      permissionAddPacking: 'permissionAddPacking',
      permissionPrintPacking: 'permissionPrintPacking'
    }),

    dropdownItems () {
      const items = []

      if (this.permissionAddPacking) {
        items.push({
          text: 'Редактировать',
          icon: 'edit',
          emitName: 'openModalEdit'
        })
      }

      return items
    }
  },

  methods: {
    openModalEdit (item) {
      this.openModal('packaging')
      this.updatedItem = item
    },

    closeModalEdit () {
      this.closeModal()
      this.updatedItem = {}
    },

    selectRow (val) {
      this.$emit('selectRowsPacking', val)
    }
  }
}
</script>
