/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 19,
    height: 17,
    viewBox: '0 0 19 17',
    data: '<path pid="0" d="M.5 8.5h17m0 0l-6.892-7m6.892 7l-6.892 7" _stroke="currentColor" stroke-width="1.5"/>'
  }
})
