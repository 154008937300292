import { fixedNumberCopy } from '@/utils/number'
import { toNum } from '@/utils/string'

// TODO DELETE
export function formulaTotalWeight (data) {
  const density = toNum(data?.material?.density)
  if (!density) return ''

  const d = toNum(data.d_wire)
  if (!d) return ''

  const l = toNum(data.sweep_length)
  if (!l) return ''

  const value = (3.14 * l * Math.pow(d, 2) * density) / (4 * Math.pow(10, 9))
  // TODO !!!
  data.weight = value

  return value ? fixedNumberCopy(value) : ''
}
