import { formatNum } from '@/utils/format/number'
import { createColOrderLinks, createColConsignmentLinks, createColAccountNumberLinks } from '@/const/table/columns'
import { getMapped } from '@/utils/analytics/reclamationInner'

const RECLAMATION_INNER_REPORT_INFO = {
  thCountReclamation: {
    title: 'Кол-во внутренних рекламаций',
    name: 'count'
  },
  thDecision: {
    title: 'Принятое решение',
    name: 'type'
  },
  thReclamationReason: {
    title: 'Первопричина рекламации',
    name: 'reason'
  },
  thConsignmentLink: {
    title: 'Партия',
    name: 'consignment_id'
  },
  chartDatasetCountReclamation: {
    label: 'Кол-во внутренних рекламаций',
    byKey: 'count'
  }
}

const RECLAMATION_INNER_REPORT_TYPE = [
  {
    id: 1,
    name: 'По первопричине',
    value: 'reason',
    table: {
      columns: [
        RECLAMATION_INNER_REPORT_INFO.thReclamationReason,
        RECLAMATION_INNER_REPORT_INFO.thCountReclamation,
        RECLAMATION_INNER_REPORT_INFO.thDecision,
        RECLAMATION_INNER_REPORT_INFO.thConsignmentLink
      ]
    },
    chart: {
      labels: 'reason',
      datasets: [RECLAMATION_INNER_REPORT_INFO.chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 2,
    name: 'По ответственным',
    value: 'responsible',
    table: {
      columns: [
        {
          title: 'Ответственный за несоответствие',
          name: 'responsible'
        },
        RECLAMATION_INNER_REPORT_INFO.thCountReclamation,
        RECLAMATION_INNER_REPORT_INFO.thReclamationReason,
        RECLAMATION_INNER_REPORT_INFO.thConsignmentLink
      ]
    },
    chart: {
      labels: 'responsible',
      datasets: [RECLAMATION_INNER_REPORT_INFO.chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 3,
    name: 'По решению',
    value: 'type',
    table: {
      columns: [
        RECLAMATION_INNER_REPORT_INFO.thDecision,
        RECLAMATION_INNER_REPORT_INFO.thCountReclamation,
        RECLAMATION_INNER_REPORT_INFO.thConsignmentLink
      ]
    },
    chart: {
      labels: 'type',
      datasets: [RECLAMATION_INNER_REPORT_INFO.chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 4,
    name: 'По потери металла',
    value: 'loss',
    table: {
      columns: [
        {
          title: 'Марка стали',
          name: 'parameter',
          ...createColConsignmentLinks()
        },
        {
          title: 'Вес, кг',
          name: 'weight',
          handlerFormat: formatNum
        }
      ],
      isTreeTable: true,
      treeKeys: {
        category: 'd_wires',
        categoryItem: 'inner_reclamations'
      }
    },
    excel: {
      getMapped
    }
  }
]

const REPORTS_TYPE_DATA = [
  {
    value: 'order',
    name: 'Статистика заказов',
    filters: { isOrderType: true },
    table: {
      classes: 'table-report table-report--order',
      columnsBefore: [
        {
          title: 'Год',
          name: 'year',
          rowspan: 2
        },
        {
          title: 'Период',
          name: 'period',
          rowspan: 2
        },
        {
          title: 'Поступило',
          colspan: 2
        },
        {
          title: 'Выпущено',
          colspan: 3
        },
        {
          title: 'Реализовано',
          colspan: 3
        }
      ],
      columns: [
        {
          title: 'Год',
          name: 'year'
        },
        {
          title: 'Период',
          name: 'period'
        },
        {
          title: 'Заказов',
          name: 'order_new',
          handlerFormat: formatNum
        },
        {
          title: 'Позиций',
          name: 'position_new',
          handlerFormat: formatNum
        },
        {
          title: 'Заказов',
          name: 'order_finish',
          handlerFormat: formatNum
        },
        {
          title: 'Позиций',
          name: 'position_finish',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во изделий',
          name: 'springs_finish',
          handlerFormat: formatNum
        },
        {
          title: 'Заказов',
          name: 'order_archive',
          handlerFormat: formatNum
        },
        {
          title: 'Позиций',
          name: 'position_archive',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во изделий',
          name: 'springs_archive',
          handlerFormat: formatNum
        }
      ],
      handlerColumns: (cols) => cols.slice(2, cols.length)
    },
    chart: {
      labels: 'period',
      datasets: [
        {
          label: 'Количество выпущенных изделий',
          byKey: 'springs_finish'
        },
        {
          label: 'Количество реализованных изделий',
          byKey: 'springs_archive'
        }
      ]
    },
    excel: {
      handledFields: formatExcelFieldsTitle
    }
  },
  {
    value: 'order-expand',
    name: 'По типам пружин и способу навивки',
    filters: {
      isApplicationArea: true,
      isSpringType: true,
      isMethodWinding: true,
      isOrderType: true,
      isWorkshop: true,
      isMaterial: true,
      isShape: true
    },
    table: {
      classes: 'table-report table-report--order-expand',
      columnsBefore: [
        {
          title: 'Год',
          name: 'year',
          rowspan: 2
        },
        {
          title: 'Период',
          name: 'period',
          rowspan: 2
        },
        {
          title: 'Поступило',
          colspan: 2
        },
        {
          title: 'Выпущено',
          colspan: 2
        },
        {
          title: 'Реализовано',
          colspan: 2
        }
      ],
      columns: [
        {
          title: 'Год',
          name: 'year'
        },
        {
          title: 'Период',
          name: 'period'
        },
        {
          title: 'Позиций',
          name: 'position_new',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во изделий',
          name: 'springs_new',
          handlerFormat: formatNum
        },
        {
          title: 'Позиций',
          name: 'position_finish',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во изделий',
          name: 'springs_finish',
          handlerFormat: formatNum
        },
        {
          title: 'Позиций',
          name: 'position_archive',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во изделий',
          name: 'springs_archive',
          handlerFormat: formatNum
        }
      ],
      handlerColumns: (cols) => cols.slice(2, cols.length),
      isRowActive: true
    },
    chart: {
      labels: 'period',
      datasets: [
        {
          label: 'Количество выпущенных изделий',
          byKey: 'springs_finish'
        },
        {
          label: 'Количество реализованных изделий',
          byKey: 'springs_archive'
        }
      ]
    },
    excel: {
      handledFields: formatExcelFieldsTitle
    },
    modal: {
      title: 'По типам пружин и способу навивки',
      columns: [
        {
          title: 'Год',
          name: 'year'
        },
        {
          title: 'Период',
          name: 'period'
        },
        {
          title: 'Поступило',
          name: 'orders_new',
          ...createColOrderLinks()
        },
        {
          title: 'Выпущено',
          name: 'orders_finish',
          ...createColOrderLinks()
        },
        {
          title: 'Реализовано',
          name: 'orders_archive',
          ...createColOrderLinks()
        }
      ]
    }
  },
  {
    value: 'stage',
    name: 'По этапу и исполнителям',
    filters: {
      isStage: true,
      isResponsible: true,
      isExecutor: true
    },
    table: {
      columns: [
        {
          title: 'Ответственный',
          name: 'executor'
        },
        {
          title: 'Всего позиций исполнено',
          name: 'position',
          handlerFormat: formatNum
        },
        {
          title: 'Всего кол-во изделий обработано',
          name: 'springs',
          handlerFormat: formatNum
        },
        {
          title: 'Общий вес металла, кг',
          name: 'weight',
          handlerFormat: formatNum
        }
      ]
    }
  },
  {
    value: 'reclamation-inner-by-type',
    name: 'По внутренним рекламациям',
    filters: { isReclamationType: true },
    table: {
      columns: []
    }
  },
  {
    value: 'wire-consumption',
    name: 'Расход проволоки',
    filters: {
      isMaterial: true,
      isOrderType: true,
      isMethodWinding: true,
      isWorkshop: true,
      isSpringType: true,
      isShape: true
    },
    table: {
      columns: [
        {
          title: 'Марка стали',
          name: 'parameter',
          ...createColOrderLinks()
        },
        {
          title: 'Чистый вес, кг',
          name: 'weight_clear',
          handlerFormat: formatNum
        },
        {
          title: 'Общий вес, кг',
          name: 'weight_dirty',
          handlerFormat: formatNum
        }
      ],
      isTreeTable: true,
      treeKeys: {
        category: 'd_wires',
        categoryItem: 'orders'
      }
    }
  },
  {
    value: 'overdue-orders',
    name: 'Кол-во просроченных заказов',
    filters: {
      isDelayReason: true,
      isMethodWinding: true,
      isOrderType: true,
      isWorkshop: true,
      isSpringType: true,
      isShape: true
    },
    table: {
      columns: [
        {
          title: 'Способ навивки',
          name: 'parameter'
        },
        {
          title: 'Кол-во заказов',
          name: 'sum_orders',
          handlerFormat: formatNum
        },
        {
          title: 'Кол-во позиций',
          name: 'sum_positions',
          handlerFormat: formatNum
        }
      ],
      isRowActive: true
    },
    modal: {
      title: 'Кол-во просроченных заказов',
      columns: [
        {
          title: 'Способ навивки',
          name: 'parameter'
        },
        {
          title: 'Заказы',
          name: 'orders',
          ...createColOrderLinks()
        },
        {
          title: 'Кол-во позиций',
          name: 'sum_positions',
          handlerFormat: formatNum
        }
      ]
    }
  },
  {
    value: 'launched-orders-technical-department',
    name: 'Количество запущенных заказов ТО',
    filters: { isResponsible: true },
    table: {
      columns: [
        {
          title: 'Инженер-технолог',
          name: 'tech_manager'
        },
        {
          title: 'Всего заказов',
          name: 'count_orders',
          handlerFormat: formatNum
        },
        {
          title: 'Всего позиций',
          name: 'count_positions',
          handlerFormat: formatNum
        }
      ]
    },
    chart: {
      labels: 'tech_manager',
      datasets: [{ label: 'Кол-во позиций', byKey: 'count_positions' }]
    }
  },

  {
    value: 'packages',
    name: 'По упаковке',
    filters: { isTypePacking: true, isPacker: true },
    table: {
      columns: [
        {
          title: 'Номер упаковщика',
          name: 'packer'
        },
        {
          title: 'Кол-во упакованных изделий',
          name: 'amount',
          handlerFormat: formatNum
        },
        {
          title: 'Вес, кг',
          name: 'weight',
          handlerFormat: handlerWeightPacking
        },
        {
          title: 'Вид упаковки',
          name: 'package_type'
        },
        {
          title: 'Номер счёта и позиция',
          name: 'order_position',
          ...createColAccountNumberLinks()
        }
      ]
    },
    excel: {
      handledFields: formatExcelFieldsTitle,
      handlerRows: handlerRowsPacking
    },
    chart: {
      labels: 'packer',
      datasets: [{ label: 'Кол-во изделий', byKey: 'amount' }]
    }
  }
]

const ANALYTICS_PRINT_TYPE_LIST = [
  { value: 'table', title: 'Таблица' },
  { value: 'chart', title: 'График' }
]

function formatExcelFieldsTitle (title, name) {
  if (name.endsWith('_new')) {
    return `${title} (П)`
  }
  if (name.endsWith('_finish')) {
    return `${title} (В)`
  }
  if (name.endsWith('_archive')) {
    return `${title} (Р)`
  }
  return title
}

function handlerRowsPacking (data) {
  return data.map(item => ({
    ...item,
    weight: item.weight === null ? 'Вес не указан' : item.weight,
    order_position: item.order_position
      ? 'счёт №' + item.order_position?.account_number + ' (позиция №' + item.order_position?.position_sort + ')'
      : null
  }))
}

function handlerWeightPacking (value) {
  if (value !== null) return formatNum(value)

  return 'Вес не указан'
}

export {
  REPORTS_TYPE_DATA,
  ANALYTICS_PRINT_TYPE_LIST,
  RECLAMATION_INNER_REPORT_TYPE,
  RECLAMATION_INNER_REPORT_INFO
}
