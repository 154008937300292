import { vuexHandbook } from '@/store/composition/handbook'
import { TypePackagingApi, TYPE_PACKAGING_URL } from '@/api/handbook/TypePackaging/TypePackagingApi'
import { showAlertError } from '@/utils/store/alert'

const state = () => ({
  ...vuexHandbook.createState(),
  api: TypePackagingApi,
  url: TYPE_PACKAGING_URL
})

const getters = {
  ...vuexHandbook.createGetters()
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions(),

  async hideItem ({ dispatch, commit, state }, { id }) {
    try {
      await state.api.destroy(id)
      dispatch('loadList')
    } catch (e) {
      showAlertError(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
