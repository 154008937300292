/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/packaging': {
    width: 15,
    height: 17,
    viewBox: '0 0 15 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.361 17l6.861-3.961a1 1 0 00.5-.866V4.25L7.862.289a1 1 0 00-1 0L0 4.25v7.923a1 1 0 00.5.866L7.361 17zm-.5-8.211L1 5.405v6.768l5.861 3.384V8.789zM1.5 4.539l5.861 3.384 2.547-1.471-5.891-3.366L1.5 4.539zm9.41 1.334L5.02 2.507 7.36 1.155l5.861 3.384-2.311 1.334zm2.812-.468l-5.86 3.384v6.768L10 14.322v-3.167L12 10v3.167l1.722-.994V5.405z" _fill="currentColor"/>'
  }
})
