export const CHANGELOG_SUBJECT_TYPE = 'App\\Models\\Order\\Order'

export const PACKING_STATUSES = [
  {
    name: 'Передано на отгрузку',
    value: 'transferred',
    icon: 'arrow-right',
    className: 'status status--orange',
    svgClass: 'svg-icon--arrow-right'
  },
  {
    name: 'Упаковано',
    value: 'packed',
    icon: 'button/packaging',
    className: 'status status--green',
    svgClass: 'svg-icon--packaging'
  }
]
